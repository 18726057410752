import Vue from 'vue'
import App from './App.vue'
import { Image, Input, Button } from 'element-ui';
import {Toast} from 'vant';
import 'vant/lib/index.css';
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import 'lib-flexible'
import axios from 'axios'

Vue.use(Image);
Vue.use(Input);
Vue.use(Button);
Vue.use(Toast);
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$toast = Toast
new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
