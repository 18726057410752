<template>
  <div class="main">
    <div class="header">
      <div class="header-box">
        <div class="leftlogo">
          <div class="logo">
            <el-image :src="logoUrl" fit="contain"></el-image>
          </div>
        </div>    
        <div class="nav">
          <span v-for="(item,index) in navList" :key="index" @click="nvaHadle(index,item.refs,60)" :class="{active:navIndex==index}">{{item.name}}</span>
        </div>
      </div>
    </div>
    <div class="banner" ref="banner">
      <div class="banner-box">
        <div class="left-banner">
          <div class="title">汽车行业领先的移动云计算平台</div>
          <div class="font">
            <span v-for="(item,index) in bannerList" :key="index">{{item}}</span>
          </div>
          <div class="layer">
            <div class="left-layer">
               <div class="layer-box"><el-image :src="brainUrl" fit="cover"></el-image></div>
            </div>
            <div class="link-box">
              <div class="link">
                <a href="http://www.smart360.cn/login#/">
                  <span><el-image :src="linktop" fit="cover"></el-image></span>
                </a>
              </div>
              <div class="link">
                <a href="https://a.ftms.com.cn/#/login">
                  <span><el-image :src="linkbottom" fit="cover"></el-image></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="right-handle"></div>
      </div>
    </div>
    <div class="product" ref="product">
      <div class="title">产品介绍</div>
      <div class="product-box">
        <div class="line" v-for="(item,index) in productList" :key="index" :class="{evt:index%2==0}">
          <div class="logo">
            <span><el-image :src="item.url" fit="contain"></el-image></span>
          </div>
          <div class="right">
            <div class="tips" :style="{color:item.color}">{{item.tips}}</div>
            <div class="state">{{item.state}}</div>
            <div class="content">{{item.content}}</div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="customer" ref="customer">
      <div class="title">主要客户</div>
      <div class="customer-box">
        <div class="tips">厂商客户</div>
        <div class="content">
          <el-image :src="item" fit="contain" v-for="(item,index) in groupUrls" :key="index" style="width:23.5%;height: 110px;margin-bottom: 0.3rem;margin-right: 1.5%;"></el-image>
        </div>
      </div>
      <div class="customer-box">
        <div class="tips">合作平台</div>
        <!-- <div class="content list">
          <span v-for="(item,index) in platList" :key="index"><el-image :src="item.url" fit="contain" style="width:85%"></el-image></span>
        </div> -->
        <div class="content">
          <el-image :src="item" fit="contain" v-for="(item,index) in partnerUrls" :key="index" style="width:23.5%;height: 110px;margin-bottom: 0.3rem;margin-right: 1.5%;"></el-image>
        </div>
      </div>
    </div>
    <div class="connect-us" ref="connecUs">
      <div class="title">联系我们</div>
      <div class="connecUs-box">
        <div class="left">
          <div class="title">公司介绍</div>
          <div class="content">
            <div class="text-box">
             Smart360成立于2013年，是国内领先的移动云计算平台，为汽车行业销售、服务、价值链以及数据业务提供卓越的工具解决方案。
            </div>
            <div class="text-box">      
            公司为中关村高新技术企业和国家高新技术企业，是美国硅谷顶级投资机构DCM投资的互联网公司。
            </div>
            <div class="text-box">
             公司主要产品Smart360、Ucar360、一丰拍、小猪汽车专家，服务的客户包括10家以上的汽车厂商，5000家以上的汽车经销商。
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">
            <span>合作申请</span>
            <span>
              <i class="imgs"><el-image :src="gphoneUrl" fit="contain"></el-image></i>
              <i>400-9626-360</i>
            </span>
          </div>
          <div class="ipt">
            <div class="input-box">
              <span class="spc">公司名称</span>
              <el-input v-model="companyName"></el-input>
            </div>
            <div class="input-box">
              <span class="spc">姓名</span>
              <el-input v-model="userName"></el-input>
            </div>
            <div class="input-box">
              <span class="spc">联系方式</span>
              <el-input v-model="phone"></el-input>
            </div>
            <div class="input-box">
              <span class="spc"></span>
              <el-button type="primary" style="width:100%;letter-spacing:3px" @click="submit">提交申请</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="consociation">
        <div class="car-img">
          <el-image :src="carUrl" fit="contain"></el-image>
        </div>
        
      </div>
    </div>
    
    <div class="footer">
      <div class="address">
        <div class="phone">
          <span class="imgs"><el-image :src="phoneUrl" fit="contain"></el-image></span>
          <span>400-9626-360</span>
          <!-- <span>010-82608696</span> -->
        </div>
        <div class="email">
          <span class="imgs"><el-image :src="emailUrl" fit="contain"></el-image></span>
          <span>service@smart360.cn</span>
        </div>
        <div class="company-add">
          <span class="imgs"><el-image :src="companyUrl" fit="contain"></el-image></span>
          <span>北京市海淀区中关村大街甲59号文化大厦1803</span>
        </div>
      </div>
      <div class="record">All Rights Reserved © Smart360 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备13038893号</a></div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import {applications} from '@/api/api'
export default {
  name: 'Home',
  data(){
    return {
      companyName:'',
      phone:'',
      userName:'',
      logoUrl: require('@/assets/logo.png'),
      brainUrl: require('@/assets/brain.png'),
      groupUrls: [
        require('@/assets/group/1.png'),
        require('@/assets/group/2.png'),
        require('@/assets/group/3.png'),
        require('@/assets/group/4.png'),
        require('@/assets/group/5.png'),
        require('@/assets/group/6.png'),
        require('@/assets/group/7.png'),
        require('@/assets/group/8.png'),
      ],
      partnerUrls: [
        require('@/assets/partner/1.png'),
        require('@/assets/partner/2.png'),
        require('@/assets/partner/3.png'),
        require('@/assets/partner/4.png'),
        require('@/assets/partner/5.png'),
        require('@/assets/partner/6.png'),
        require('@/assets/partner/7.png'),
        require('@/assets/partner/8.png'),
        require('@/assets/partner/9.png'),
        require('@/assets/partner/10.png'),
        require('@/assets/partner/11.png'),
        require('@/assets/partner/12.png'),
      ],
      carUrl: require('@/assets/car.png'),
      phoneUrl: require('@/assets/phone.png'),
      gphoneUrl: require('@/assets/gphone.png'),
      emailUrl: require('@/assets/email.png'),
      companyUrl: require('@/assets/address.png'),
      link1Url: require('@/assets/01.png'),
      link2Url: require('@/assets/02.png'),
      arrowUrl: require('@/assets/03.png'),
      linktop:require('@/assets/linktop.png'),
      linkbottom:require('@/assets/linkbottom.png'),
      platUrl: require('@/assets/plat.png'),
      navList:[
        {name:'首页',refs:'banner'},
        {name:'产品介绍',refs:'product'},
        {name:'主要客户',refs:'customer'},
        {name:'联系我们',refs:'connecUs'},
      ],
      bannerList:['简单','专业','智能','高效'],
      navIndex:0,
      mobile:'',
      productList:[
        {url:require('@/assets/2.png'),tips:'Smart360',color:'#007AD3',state:'服务对象：汽车厂商和经销商',content:'汽车行业领先的移动CRM平台，专注销售过程数据化管控，为汽车厂商和经销商集团提供可视化的智能AI分析，有效提升客户到店率和成交率已服务10个品牌汽车厂商5000+汽车经销商。'},
        {url:require('@/assets/1.png'),tips:'Ucar360',color:'#FF451C',state:'服务对象：汽车厂商和经销商',content:'汽车经销商二手车业务的聚合拍卖平台，已对接优信拍、车易拍、天天拍车、汽车街等12家主流的汽车拍卖平台，高效提升二手车处置效率，快速提升溢价率。'},
        // {url:require('@/assets/4.png'),tips:'小猪汽车专家',color:'#00B1C1',state:'服务对象：汽车经销商',content:'将汽车经销商的销售顾问、服务顾问、客服专员、保险专员等组建成SBU战略单元，共同为车主全生命周期的需求提供服务，提升保客营销转化效果，提高客户满意度。'},
        {url:require('@/assets/360+.png'),tips:'360+',color:'#00B1C1',state:'服务对象：汽车厂商和经销商',content:'汽车销售管理APP与企业微信互通，可实时记录微信聊天全过程，客户资源永留存。监控销售过程行为，沉淀数据进行智能分析及预测提升销售成交率。'},
		//{url:require('@/assets/3.png'),tips:'汽车裂变商城',color:'#F29600',state:'服务对象：汽车经销商',content:'为汽车经销商提供在线数字化商城，通过租赁式试驾服务实现客户裂变服务，并为终端车主提供一键直达厂商的投诉管理服务。'}
		{url:require('@/assets/byd-crm.png'),tips:'比亚迪精诚拍',color:'#F29600',state:'服务对象：汽车厂商和经销商',content:'比亚迪精诚拍聚合服务平台为经销商处置二手车，省时省力省心省钱，公开、透明、高效。通过这个平台的运用，一方面帮助经销商实现高溢价、快速流通；另一方面让二手车交易阳光可视化，与置换审核关联，提高工作效率和返款速度。精诚拍平台聚合了行业优势资源，对接了行业内主流的线上拍卖平台，实现一车多拍，销售渠道更宽、更快。'},
        {url:require('@/assets/jietu.png'),tips:'捷竞价',color:'#00B1C1',state:'服务对象：汽车厂商和经销商',content:'捷竞价聚合服务平台为经销商处置二手车，省时省力省心省钱，公开、透明、高效。通过这个平台的运用，一方面帮助经销商实现高溢价、快速流通；另一方面让二手车交易阳光可视化，与置换审核关联，提高工作效率和返款速度。精诚拍平台聚合了行业优势资源，对接了行业内主流的线上拍卖平台，实现一车多拍，销售渠道更宽、更快。'}
      ],
      platList:[
        {url:require('@/assets/16.png')},
        {url:require('@/assets/15.png')},
        {url:require('@/assets/14.png')},
        {url:require('@/assets/13.png')},
        {url:require('@/assets/12.png')},
        {url:require('@/assets/11.png')},
        {url:require('@/assets/10.png')},
        {url:require('@/assets/9.png')},
        {url:require('@/assets/8.png')},
        {url:require('@/assets/7.png')},
        {url:require('@/assets/6.png')},
        {url:require('@/assets/5.png')},
      ]
    }
  },
  methods:{
    submit(){
      if(!this.companyName||!this.phone||!this.userName){
        Message({
          type:'warning',
          message:'请完善申请信息'
        })
        return
      }
      if(!this.checkPhone(this.phone)){
        Message({
          type:'warning',
          message:'手机号格式错误'
        })
        return
      }
      applications({
        applicationType: "车商",
        brand: " ",
        city: " ",
        companyName: this.companyName,
        companyType: " ",
        duties: " ",
        phone: this.phone,
        province: " ",
        sex: " ",
        userName: this.userName,
      }).then(res=>{
        if(res.data.status == 200){
          Message({
            type:'success',
            message:'您的申请已提交'
          })
          this.companyName = ''
          this.phone = ''
          this.userName = ''
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    checkPhone(phone){ 
        if(!(/^1[34578]\d{9}$/.test(phone))){ 
          return false; 
        }else{
          return true 
        }
    },
    nvaHadle(index, target, speed){
      this.navIndex = index
      this.goAssignBlock(target, speed)
    },
    goAssignBlock(el, speed) {
      let windowH = window.innerHeight; //浏览器窗口高度
      let h = this.$refs[el].offsetHeight; //模块内容高度
      let t = this.$refs[el].offsetTop; //模块相对于内容顶部的距离
      console.log(h)
      console.log(t)
      let top = t - (windowH - h) / 2; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滚动条距离顶部高度
      let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
      let requestId;
      //采用requestAnimationFrame，平滑动画
      function step() {
        //判断让滚动条向上滚还是向下滚
        if (scrollTop < top) {
          if (currentTop <= top) {
            //   window.scrollTo(x,y) y为上下滚动位置
            window.scrollTo(0, currentTop);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向下滚动
          currentTop += speed;
        } else {
          if (top <= currentTop) {
            //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
            window.scrollTo(0, currentTop - speed);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向上滚动
          currentTop -= speed;
        }
      }
      window.requestAnimationFrame(step);
    }
  },
  mounted(){
    // console.log(document.documentElement.clientWidth)
    // console.log(document.documentElement.clientHeight)
    // let cWidth = document.documentElement.clientWidth
    // let cHeight = document.documentElement.clientHeight
    // let newHeight = cWidth / (cWidth / cHeight)
    // console.log(newHeight)
  },
  created(){}
}
</script>
<style scoped lang="scss">
.main{
  width:100%;
  font-family:PingFangSC-Regular;
  .header{
    position: fixed;
    top:0;
    z-index:100;
    width:100%;
    background: #fff;
    box-shadow:8px 0 8px #666;
    .header-box{
      width:67%;
      height:1.3rem;
      margin:0 auto;
      display: flex;
      justify-content: space-between;
      .leftlogo{
        flex:1;
        display: flex;
        align-items: center;
        .logo{
          width:2.7rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .nav{
        flex:0.8;
        display: flex;
        justify-content:space-between;
        align-items: center;
        span{
          font-size:0.3rem;
          cursor: pointer;
        }
        .active{
          color:#007AD3;
        }
      }
    }
    
  }
  .banner{
    width:100%;
    background: url('../../assets/banner.png') no-repeat;
    background-size:cover;
    padding-bottom:1rem;
    margin-top:1.3rem;
    .banner-box{
      width:67%;
      margin:0 auto;
      display: flex;
      justify-content: space-between;
      .left-banner{
        flex:1;
        padding-top:1rem;
        .title{
          font-size:0.8rem;
          color:#fff;
          letter-spacing:4px;
          font-weight: 600;
        }
        .font{
          margin:0.6rem 0;
          span{
            color:#ECECEC;
            font-size:20px;
            margin-right:50px;
          }
        }
        .layer{
          display: flex;
          justify-content: space-between;
          .left-layer{
            flex:1;
            .layer-box{
              width:80%;
              margin-left:10%;
            }
          }
          .link-box{
            flex:1;
            text-align: right;
            .link{
              width:60%;
              margin-left:40%;
              border-radius:5px;
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin-bottom:45px;
              cursor: pointer;
              span:nth-child(1){
                width:200px;
                text-align: center;
              }
              span:nth-child(2){
                flex:1;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  .product{
    width:69%;
    margin:40px auto;
    .title{
      width:100%;
      text-align: center;
      font-size:34px;
      margin-bottom:20px;
      color:#000;
    }
    .product-box{
      display: flex;
      flex-wrap: wrap;
      .line{
        width:49%;
        border-radius: 15px;
        margin-top:30px;
        box-shadow: 0 0 8px #ccc;
        display: flex;
        box-sizing:border-box;
        padding:20px 15px;
        background-image: linear-gradient(#FCFCFC, #FFFFFF);
        .logo{
          flex:1.6;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
             width:90%;
             display: flex;
             justify-content: center;
             align-items: center;
          }
        }
        .right{
          flex:2.5;
          .tips{
            width:90%;
            margin:0 auto 10px;
            color:#007AD3;
            font-size:30px;
            letter-spacing:1px;
          }
          .state{
            width:90%;
            margin:0 auto;
            color:#000;
            font-size:14px;
            margin-bottom:5px;
          }
          .content{
            width:95%;
            margin:0;
            margin-left:5%;
            height:100px;
            color:#555;
            font-size:14px;
            line-height: 20px;
            letter-spacing: 1px;
            padding-bottom:10px;
          }
        }
      }
      .evt{
        margin-right:2%;
      }
    }
  }
  .customer{
    width:100%;
    margin:40px auto;
    padding-bottom:40px;
    background:#E8E8E8;
    .title{
      width:67%;
      margin:0 auto;
      padding:50px 0 30px;
      text-align: center;
      font-size:34px;
      color:#000;
    }
    .customer-box{
      width:67%;
      margin:0 auto;
      .tips{
        color:#6E6E6E;
        font-size:24px;
        margin-bottom:30px;
        margin-left:15px;
      }
      .content{
        width:100%;
        margin-bottom:40px;
        display: flex;
        justify-content:start;
        flex-wrap: wrap;
      } 
      .list{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom:20px;
        span{
          width:16.6%;
          margin-bottom:22px;
        }
      }
    }
    
  }
  .connect-us{
    width:67%;
    margin:0 auto;
    position:relative;
    .title{
      width:100%;
      text-align: center;
      font-size:34px;
      margin-bottom:40px;
      color:#000;
    }
    .connecUs-box{
      display:flex;
      .left{
        flex:1.3;
        display: flex;
        flex-direction: column;
        .title{
          font-size:22px;
          color:#2B2B2B;
          text-align: left;
        }
        .content{
          width:90%;
          font-size:14px;
          line-height:25px;
          letter-spacing:1px;
          .text-box{
            margin-top:1em;
            text-indent:2em;
            font-size:16px;
            color:#555;
          }
        }
      }
      .right{
        flex:1;
        display: flex;
        flex-direction: column;
        .title{
          width:85%;
          display: flex;
          justify-content: space-between;
          text-align: left;
          span:nth-child(1){
            font-size:22px;
            color:#2B2B2B;
          }
          span:nth-child(2){
            display: flex;
            .imgs{
              display: flex;
              justify-content: center;
              align-items: center;
              width:15px;
              margin-right:8px;
            }
            i{
              font-style: normal;
              color:#8F8F8F;
              font-size:16px;
            }
          }
        }
        .ipt{
          margin-top:20px;
          width:85%;
          .input-box{
            display: flex;
            margin-bottom:20px;
            .spc{
              font-size:14px;
              width:100px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: right;
            }
            .btn{
     
            }
          }
        }
      }
    }
    .consociation{
      position: absolute;
      bottom:-150px;
      left:-35%;
      z-index:-1;
      .car-img{
        width:50%;
      }
      
    }
  }
  
  .footer{
    width:100%;
    margin-top:80px;
    padding:60px 0;
    background: #0063C1;
    .address{
      display: flex;
      justify-content: center;
      align-items: center;
      color:#fff;
      font-size:14px;
      .phone,.email,.company-add{
        display: flex;
        .imgs{
          width:25px;
        }
        span{
          margin-right:10px;
          display: flex;
          align-items: center;
        }
      }
      .email{
        margin:0 50px;
      }
      .company-add{}
    }
    .record{
      color:#CFCFCF;
      font-size:14px;
      margin-top:50px;
      text-align: center;
    }
  }
}
</style>
