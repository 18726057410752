import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    plaform: false,
}

const mutations = {
    isMobile(state, msg) {
        state.plaform = msg 
    },
}

const actions = {

}

const store = new Vuex.Store({
    state,
    mutations,
    actions
});
 
export default store;