<template>
  <div class="main">
     <PC v-if="!isMobile"/>
     <MOBILE v-else/>
  </div>
</template>

<script>
import PC from './pc'
import MOBILE from './mobile'
export default {
  name: 'Home',
  components:{
    PC,
    MOBILE
  },
  computed:{
    isMobile(){
      return this.$store.state.plaform
    }
  },
  methods:{},
  mounted(){},
  created(){}
}
</script>
<style scoped lang="scss">
.main{


}
</style>
