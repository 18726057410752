<template>
  <div class="main">
      <div class="top-header" ref="banner">
        <div class="logo">
          <span><el-image :src="logoUrl" fit="cover"></el-image></span>
        </div>
        <div class="tips1">汽车行业领先的移动云计算平台</div>
        <div class="tips2">
          <span>简单</span>
          <span>专业</span>
          <span>智能</span>
          <span>高效</span>
        </div>
        <div class="brain">
          <span><el-image :src="brainUrl" fit="cover"></el-image></span>
        </div>
        <div class="btn1"><a href="https://data.smart360.cn/download">下载Smart360</a></div>
        <div class="btn2"><a href="https://data.smart360.cn/downloadUcar360">下载Ucar360</a></div>
        <div class="down">
          <div class="downimg"><el-image :src="downUrl" fit="cover"></el-image></div>
        </div>
      </div>
      <div class="product" ref="product">
        <div class="title">产品介绍</div>
        <div class="product-box" v-for="(item,index) in productList" :key="index"
          :style="{borderBottom:index==3?'none':'1px solid #ddd'}">
          <div class="top">
            <span><el-image :src="item.url" fit="cover"></el-image></span>
            <span :style="{color:item.color}">{{item.tips}}</span>
          </div>
          <div class="tips">{{item.state}}</div>
          <div class="content">{{item.content}}</div>
        </div>
      </div>
      <div class="customer" ref="customer">
        <div class="title">主要客户</div>
        <div class="customer-box">
          <div class="tips">厂商客户</div>
          <div class="content">
            <span v-for="(item,index) in iconList" :key="index">
              <el-image :src="item.url" fit="contain"></el-image>
            </span>
            <span></span>
          </div>
        </div>
        <div class="customer-box">
          <div class="tips">合作平台</div>
          <div class="content">
            <span v-for="(item,index) in groupList" :key="index">
              <el-image :src="item.url" fit="contain"></el-image>
            </span>
          </div>
        </div>
      </div>
      <div class="connect-us" ref="connecUs">
        <div class="title">联系我们</div>
        <div class="fontb">Smart360成立于2013年，是国内领先的移动云计算平台，为汽车行业销售、服务、价值链以及数据业务提供卓越的工具解决方案。</div>
        <div class="fontb">公司为中关村高新技术企业和国家高新技术企业，是美国硅谷顶级投资机构DCM投资的互联网公司。</div>
        <div class="fontb">公司主要产品Smart360、Ucar360、一丰拍、小猪汽车专家，服务的客户包括10家以上的汽车厂商，5000家以上的汽车经销商。</div>
      </div>
      <div class="teamwork">
        <div class="title">合作申请</div>
        <div class="ipt">
          <div class="input-box">
            <span class="spc">公司名称</span>
            <el-input v-model="companyName"></el-input>
          </div>
          <div class="input-box">
            <span class="spc">姓名</span>
            <el-input v-model="userName"></el-input>
          </div>
          <div class="input-box">
            <span class="spc">联系方式</span>
            <el-input v-model="phone"></el-input>
          </div>
          <div class="input-box">
            <span class="spc"></span>
            <el-button type="primary" style="width:100%;letter-spacing:3px" @click="submit">提交申请</el-button>
          </div>
        </div>
        <div class="leftbox">
          <el-image :src="leftUrl" fit="cover"></el-image>
        </div>
      </div>
      <div class="footer">
        <div class="company">
          <div class="phone">
            <span class="imgs"><el-image :src="phoneUrl" fit="contain"></el-image></span>
            <span>400-9626-360</span>
            <!-- <span>010-82608696</span> -->
          </div>
          <div class="email">
            <span class="imgs"><el-image :src="emailUrl" fit="contain"></el-image></span>
            <span>service@smart360.cn</span>
          </div>
        </div>
        <div class="address">
          <span class="imgs"><el-image :src="companyUrl" fit="contain"></el-image></span>
          <span>北京市海淀区中关村大街甲59号文化大厦1803</span>
        </div>
        <div class="record">
            <div>All Rights Reserved © Smart360 京ICP备 13038893-9号 </div>
            <div>京公网安备案11010802016680</div>
        </div>    
      </div>
      <div class="bottomnav">
        <div v-for="(item,index) in navList" 
        class="nav"
        :key="index" 
        @click="nvaHadle(index,item.refs,60)" 
        >
          <span class="top">
            <img v-if="navIndex!=index" :src="item.curUrl" fit="cover" style="width:100%;height:100%;">
            <img v-if="navIndex==index" :src="item.actUrl" fit="cover" style="width:100%;height:100%;">
          </span>
          <span class="bottom" :class="{active:navIndex==index}">{{item.name}}</span>
        </div>
      </div>
  </div>
</template>

<script>
import {applications} from '@/api/api'
export default {
  name: 'Home',
  data(){
    return {
      companyName:'',
      userName:'',
      phone:'',
      logoUrl: require('@/assets/mobile/16.png'),
      brainUrl: require('@/assets/mobile/17.png'),
      downUrl: require('@/assets/mobile/18.png'),
      groupUrl: require('@/assets/mobile/19.png'),
      phoneUrl: require('@/assets/mobile/15.png'),
      emailUrl: require('@/assets/mobile/14.png'),
      companyUrl: require('@/assets/mobile/13.png'),
      leftUrl: require('@/assets/mobile/01.png'),
      iconList:[
        {url:require('@/assets/mobile/008.png')},
        {url:require('@/assets/mobile/007.png')},
        {url:require('@/assets/mobile/006.png')},
        {url:require('@/assets/mobile/005.png')},
        {url:require('@/assets/mobile/004.png')},
        {url:require('@/assets/mobile/003.png')},
        {url:require('@/assets/mobile/002.png')},
        {url:require('@/assets/mobile/001.png')}
      ],
      groupList:[
        {url:require('@/assets/mobile/112.png')},
        {url:require('@/assets/mobile/111.png')},
        {url:require('@/assets/mobile/110.png')},
        {url:require('@/assets/mobile/109.png')},
        {url:require('@/assets/mobile/108.png')},
        {url:require('@/assets/mobile/107.png')},
        {url:require('@/assets/mobile/106.png')},
        {url:require('@/assets/mobile/105.png')},
        {url:require('@/assets/mobile/104.png')},
        {url:require('@/assets/mobile/103.png')},
        {url:require('@/assets/mobile/102.png')},
        {url:require('@/assets/mobile/101.png')}
      ],
      navList:[
        {name:'首页',refs:'banner',curUrl:require('@/assets/mobile/12.png'),actUrl:require('@/assets/mobile/11.png')},
        {name:'产品介绍',refs:'product',curUrl:require('@/assets/mobile/10.png'),actUrl:require('@/assets/mobile/08.png')},
        {name:'主要客户',refs:'customer',curUrl:require('@/assets/mobile/07.png'),actUrl:require('@/assets/mobile/06.png')},
        {name:'联系我们',refs:'connecUs',curUrl:require('@/assets/mobile/03.png'),actUrl:require('@/assets/mobile/02.png')},
      ],
      navIndex:0,
      mobile:'',
      productList:[
        {url:require('@/assets/mobile/19.png'),tips:'Smart360',color:'#007AD3',state:'服务对象：汽车厂商和经销商',content:'汽车行业领先的移动CRM平台，专注销售过程数据化管控，为汽车厂商和经销商集团提供可视化的智能AI分析，有效提升客户到店率和成交率已服务10个品牌汽车厂商5000+汽车经销商。'},
        {url:require('@/assets/mobile/20.png'),tips:'Ucar360',color:'#FF451C',state:'服务对象：汽车厂商和经销商',content:'汽车经销商二手车业务的聚合拍卖平台，已对接优信拍、车易拍、天天拍车、汽车街等12家主流的汽车拍卖平台，高效提升二手车处置效率，快速提升溢价率。'},
        //{url:require('@/assets/mobile/21.png'),tips:'小猪汽车专家',color:'#00B1C1',state:'服务对象：汽车经销商',content:'将汽车经销商的销售顾问、服务顾问、客服专员、保险专员等组建成SBU战略单元，共同为车主全生命周期的需求提供服务，提升保客营销转化效果，提高客户满意度。'},
        {url:require('@/assets/360+.png'),tips:'360+',color:'#00B1C1',state:'服务对象：汽车厂商和经销商',content:'汽车销售管理APP与企业微信互通，可实时记录微信聊天全过程，客户资源永留存。监控销售过程行为，沉淀数据进行智能分析及预测提升销售成交率。'},
        {url:require('@/assets/mobile/22.png'),tips:'汽车裂变商城',color:'#F29600',state:'服务对象：汽车经销商',content:'为汽车经销商提供在线数字化商城，通过租赁式试驾服务实现客户裂变服务，并为终端车主提供一键直达厂商的投诉管理服务。'}
      ]
    }
  },
  methods:{
    submit(){
      if(!this.companyName||!this.phone||!this.userName){
        this.$toast.fail('请完善申请信息');
        return
      }
      if(!this.checkPhone(this.phone)){
        this.$toast.fail('手机号格式错误');
        return
      }
      applications({
        applicationType: "车商",
        brand: " ",
        city: " ",
        companyName: this.companyName,
        companyType: " ",
        duties: " ",
        phone: this.phone,
        province: " ",
        sex: " ",
        userName: this.userName,
      }).then(res=>{
        if(res.data.status == 200){
          this.$toast.success('您的申请已提交');
          this.companyName = ''
          this.phone = ''
          this.userName = ''
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    checkPhone(phone){ 
        if(!(/^1[34578]\d{9}$/.test(phone))){ 
          return false; 
        }else{
          return true 
        }
    },
    nvaHadle(index, target, speed){
      this.navIndex = index
      this.goAssignBlock(target, speed)
    },
    goAssignBlock(el, speed) {
      let windowH = window.innerHeight; //浏览器窗口高度
      let h = this.$refs[el].offsetHeight; //模块内容高度
      let t = this.$refs[el].offsetTop; //模块相对于内容顶部的距离
      console.log(h)
      console.log(t)
      let top = t - (windowH - h) / 2; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滚动条距离顶部高度
      let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
      let requestId;
      //采用requestAnimationFrame，平滑动画
      function step() {
        //判断让滚动条向上滚还是向下滚
        if (scrollTop < top) {
          if (currentTop <= top) {
            //   window.scrollTo(x,y) y为上下滚动位置
            window.scrollTo(0, currentTop);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向下滚动
          currentTop += speed;
        } else {
          if (top <= currentTop) {
            //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
            window.scrollTo(0, currentTop - speed);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向上滚动
          currentTop -= speed;
        }
      }
      window.requestAnimationFrame(step);
    }
  },
  mounted(){},
  created(){}
}
</script>
<style scoped lang="scss">
.main{
  font-family: PingFangSC-Light;
  width:100%;
  .top-header{
    width:100%;
    background: url('../../assets/mobile/120.png') no-repeat;
    background-size:cover;
    padding:1.8rem 0 0.8rem;
    color:#fff;
    .logo{
      width:60%;
      margin:0 auto;
    }
    .tips1{
      margin:0.5rem 0;
      text-align: center;
      font-size:0.55rem;
      letter-spacing:0.03rem;
      font-weight:bold;
    }
    .tips2{
      width:57%;
      margin:0 auto;
      color:#ECECEC;
      display: flex;
      justify-content: space-between;
      font-size:0.4rem;
      letter-spacing:0.1rem;
    }
    .brain{
      margin:0.5rem auto;
      width:50%;
    }
    .btn1{
      width:48%;
      margin:0 auto;
      text-align: center;
      border:1px solid #fff;
      border-radius:1rem;
      padding:0.3rem;
      font-size:0.4rem;
    }
    .btn2{
      width:48%;
      margin:0.5rem auto;
      text-align: center;
      border:1px solid #fff;
      border-radius:1rem;
      padding:0.3rem;
      font-size:0.4rem;
    }
    .down{
      margin-top:1rem;
      .downimg{
        width:1rem;
        margin:0 auto;
      }
    }
  }
  .product{
    padding:0.5rem 0;
    background: #F3F4F5;
    .title{
      text-align: center;
      font-size:0.6rem;
      color:#000;
    }
    .product-box{
      width:85%;
      margin:0 auto;
      border-bottom:1px solid #DDDDDD;
      padding:0.7rem 0;
      .top{
        display: flex;
        margin:0.1rem 0 0.4rem;
        span:nth-child(1){
          width:1.8rem;
          margin-right:0.4rem;
        }
        span:nth-child(2){
          color:#007AD3;
          font-size:0.6rem;
          display: flex;
          align-items: center;
        }
      }
      .tips{
        color:#000;
        font-size:0.35rem;
        margin-bottom:0.1rem;
      }
      .content{
        line-height:0.55rem;
        font-size:0.35rem;
        letter-spacing: 0.007rem;
        color:#555;
      }
    }
  }
  .customer{
    margin:0.5rem 0;
    .title{
      text-align: center;
      font-size:0.6rem;
      color:#000;
    }
    .customer-box{
      width:90%;
      margin:0 auto;
      .tips{
        margin:0.5rem 0 0.3rem;
        color:#787878;
        font-size:0.3rem;
      }
      .content{
        width:100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        span{
          width:31.5%;
          margin-bottom:0.2rem;
        }
      }
    }
  }
  .connect-us{
    padding:0.5rem 0;
    background: #F3F4F5;
    .title{
      text-align: center;
      font-size:0.6rem;
      color:#000;
      margin-bottom:0.6rem;
    }
    .fontb{
      width:90%;
      margin:0 auto;
      font-size:0.3rem;
      color:#626262;
      letter-spacing: 0.02rem;
      line-height: 0.6rem;
    }
  }
  .teamwork{
    padding:0 0 0.5rem ;
    background: #F3F4F5;
    position:relative;
    .title{
      width:90%;
      margin:0 auto;
      color:#0360B9;
      font-size:0.45rem;
      font-weight: bold;
      margin-bottom:0.8rem;
    }
    .ipt{
      width:85%;
      margin:0 auto;
      position: relative;
      z-index:2;
      .input-box{
        margin-bottom:0.5rem;
        display: flex;
        .spc{
          width:2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color:#606060;
        }
      }
    }
    .leftbox{
      width:2.8rem;
      position: absolute;
      bottom:0;
      z-index:1;
      left:0;
    }
  }
  .footer{
    width:100%;
    padding:0.5rem 0;
    margin-bottom:1.4rem;
    background: #0063C1;
    color:#fff;
    .company{
      width:92%;
      margin:0 auto;
      display: flex;
      justify-content: space-between;
      .phone{
        display: flex;
        .imgs{
          width:0.35rem;
          position:relative;
          top:0.04rem;
        }
        span{
          white-space: nowrap;
          margin-right:0.15rem;
          font-size:0.2rem;
        }
      }
      .email{
        display: flex;
        .imgs{
          width:0.4rem;
          position:relative;
          top:0.12rem;
          margin-right:0.1rem;
        }
        span{
          font-size:0.2rem;
        }
      }
    }
    .address{
      width:92%;
      margin:0.4rem auto;
      display: flex;
      .imgs{
        width:0.35rem;
        position:relative;
        top:0.02rem;
        margin-right:0.1rem;
      }
      span{
        font-size:0.2rem;
      }
    }
    .record{
      color:#CFCFCF;
      width:92%;
      font-size:0.2rem;
      margin:0 auto;
      div:nth-child(1){
        text-align: center;
      }
      div:nth-child(2){
        text-align: center;
      }
    }
    
  }
  .bottomnav{
    position: fixed;
    bottom:0;
    z-index:99;
    display: flex;
    justify-content:space-around;
    width:100%;
    height:1.4rem;
    background:#fff;
    .nav{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .top{
        width:0.5rem;
        height:0.5rem;
        margin-bottom:0.1rem;
      }
      .bottom{
        font-size:0.3rem;
        color:#000;
      }
      .active{
        color:#0063C1;
      }
    }
    
  }
}

</style>
