<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from './components/index.vue'
export default {
  name: 'App',
  components: {
    Home
  },
  mounted(){
    if (this._isMobile()) {
      this.$store.commit('isMobile', true)
    } else {
      this.$store.commit('isMobile', false)
    }
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>

<style>
body{
  margin:0;
  padding:0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
a {
    text-decoration: none;
    color:#fff;
}
/*正常的未被访问过的链接*/
a:link {
    text-decoration: none;
    color:#fff;
}
/*已经访问过的链接*/
a:visited {
    text-decoration: none;
    color:#fff;
}
/*鼠标划过(停留)的链接*/
a:hover {
    text-decoration: none;
    color:#fff;
}
/* 正在点击的链接，鼠标在元素上按下还没有松开*/
a:active {
    text-decoration: none;
    color:#fff;
}
/* 获得焦点的时候 鼠标松开时显示的颜色*/
a:focus {
    text-decoration: none;
    color:#fff;
}
</style>
