import axios from 'axios'
import Qs from 'qs'

const request = axios.create({
    baseURL: '',
    timeout: 10000
})

function postRequestJson(url, params) {
    let pm = params;
    return request_fuc(url, pm, 'POST', 1);
}
  
function request_fuc(url, params, method, isJson) {
    return request({
        method: method,
        url: url,
        data: method === 'POST' || method === 'PUT' || method === 'DELETE' ? (isJson == 1 ? JSON.stringify(params) : params) : null,
        params: method === 'GET' ? params : null,
        // 请求头携带信息，类型默认为form,键值模式，传参为1
        headers: {
            'Content-Type': isJson && isJson === 1 ? 'application/json' : 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        transformRequest: [function (data) {
            if (isJson !== 1) {
                data = Qs.stringify(data);
            }
            return data;
        }],
    })
}
export default {
    request,
    postRequestJson
}